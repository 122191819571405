import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})

  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if (scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })

  $(document).ready(function(){
    $(".read-more.de").on('click', function(){
      $(this).prev().toggle();
      if ($(this).text()=='Weitere Bilder ansehen') {
        $(this).text('Weniger Bilder ansehen');
      }
      else {
        $(this).text('Weitere Bilder ansehen');
      }
    });
    
    $(".read-more.en").on('click', function(){
      $(this).prev().toggle();
      if ($(this).text()=='View more') {
        $(this).text('View less');
      }
      else {
        $(this).text('View more');
      }
    });
  });

  $('li ul').parent().addClass('second-level')


  const selector = '.header-nav-list > li.second-level'
  $(selector).on({

    mouseenter: function () {
      if ($(window).width() >= 1025) {
        $(this).addClass('open')
      }
    },
    mouseleave: function () {
      if ($(window).width() >= 1025) {
        $(selector).removeClass('open')
      }
    }
  })
})
